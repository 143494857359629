<template>
    <footer v-if="hasData">
        <div
            v-if="$store.state.ui.isVisible.web.footer && showFormSubscribe"
            class="container-fluid py-md-5"
        >
            <div class="text-center">
                <div class="h3 mb-2">
                    {{ formSubscribeNewsletterHeader.title }}
                </div>
                <p>{{ formSubscribeNewsletterHeader.description }}</p>
            </div>
            <form-subscribe
                :input-disabled="!!formSubscribeNewsletter"
                :tracking-params="{i_source: 'footer_subscribe'}"
            />
        </div>

        <div class="py-0 py-md-5">
            <div class="container-fluid">
                <!-- TODO fix markup semantic by adding an
                    unordered list if there is no dt/title -->
                <!-- TODO if not needed for seo, lazy html nodes
                    from `base-accordion-item` content, when mobile -->
                <base-accordion>
                    <div class="row">
                        <base-accordion-item
                            class="col-md-6 mb-md-0 p-0 quick-links"
                            :header="$t(footerState.quickLinks.titleTranslate)"
                            :active="isAccordionActive"
                            :disabled="isAccordionActive"
                        >
                            <dl>
                                <dd
                                    v-for="(item, index) in quickLinks"
                                    :key="index"
                                    :class="item.break && 'break'"
                                >
                                    <component
                                        :is="item.href ? 'a' : 'router-link'"
                                        :to="item.to"
                                        :href="item.href || item.to"
                                        :target="item.target"
                                        :rel="item.rel"
                                    >
                                        {{ item.text ? item.text : $t(item.textTranslate) }}
                                    </component>
                                </dd>
                            </dl>
                        </base-accordion-item>
                        <div class="col-md-3">
                            <div class="row">
                                <base-accordion-item
                                    v-if="!isMobileApp"
                                    class="col-md-12 mb-md-0 p-0 apps"
                                    :header="$t('layouts_footer_col_app_heading')"
                                    :active="isAccordionActive"
                                    :disabled="isAccordionActive"
                                >
                                    <dl>
                                        <dd>
                                            <base-btn
                                                href="https://itunes.apple.com/ro/app/vivre/id802901869"
                                                target="_blank"
                                                rel="nofollow noopener"
                                                variant="link"
                                                class="btn-apps"
                                                title="Apple Store App"
                                            >
                                                <base-icon name="apple" />
                                                <span>
                                                    {{ $t('layouts_footer_col_app_link_ios') }}
                                                </span>
                                            </base-btn>
                                        </dd>
                                        <dd>
                                            <base-btn
                                                href="https://play.google.com/store/apps/details?id=com.ro.vivre"
                                                target="_blank"
                                                rel="nofollow noopener"
                                                variant="link"
                                                class="btn-apps"
                                                title="Google Store App"
                                            >
                                                <base-icon name="android" />
                                                <span>{{
                                                    $t('layouts_footer_col_app_link_android')
                                                }}</span>
                                            </base-btn>
                                        </dd>
                                    </dl>
                                </base-accordion-item>
                                <base-accordion-item
                                    v-if="legalInfo"
                                    class="col-md-12 mb-md-0 p-0"
                                    :header="$t('layouts_footer_col_legal_heading')"
                                    :active="isAccordionActive"
                                    :disabled="isAccordionActive"
                                >
                                    <dl>
                                        <dd
                                            v-for="(item, index) in legalInfo"
                                            :key="index"
                                        >
                                            <a
                                                :href="item.href"
                                                :target="item.target"
                                                :rel="item.rel"
                                            >
                                                <base-img
                                                    :lazy="true"
                                                    :src="item.image"
                                                    :alt="$t(item.textTranslate)"
                                                />
                                            </a>
                                        </dd>
                                    </dl>
                                </base-accordion-item>
                            </div>
                        </div>
                        <base-accordion-item
                            class="col-md-3 mb-md-0 p-0"
                            :header="$t('layouts_footer_col_social_heading')"
                            :active="isAccordionActive"
                            :disabled="isAccordionActive"
                        >
                            <dl>
                                <dd>
                                    <a
                                        v-for="(url, profile) in socialProfiles"
                                        :key="`socialProfiles-${profile}`"
                                        :href="url"
                                        target="_blank"
                                        rel="nofollow noopener"
                                        class="me-3"
                                    >
                                        <base-icon :name="profile" />
                                        <span class="visually-hidden">{{ profile }}</span>
                                    </a>
                                </dd>
                                <dd
                                    v-if="$store.state.ui.switch.web.global.phoneNumber
                                        && $store.state.params.api.publicContactPhone"
                                >
                                    <router-link to="/contact">
                                        <base-icon name="phone" />
                                        <span>
                                            {{ $store.state.params.api.publicContactPhone }}
                                        </span>
                                    </router-link>
                                </dd>
                            </dl>
                        </base-accordion-item>
                    </div>
                </base-accordion>
            </div>
        </div>

        <div class="copyright text-center">
            <p class="container-fluid">
                &copy; {{ new Date().getFullYear() }} | {{ $t('layouts_footer_copyright') }}.
            </p>
        </div>
    </footer>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import footerModule from '../store/modules/footer';
import { SOURCE } from '../utils/constants';
import BaseAccordion from './BaseAccordion.vue';
import BaseAccordionItem from './BaseAccordionItem.vue';

const FormSubscribe = defineAsyncComponent(() => import('./FormSubscribe.vue'));

export default {
    name: 'TheFooter',

    components: {
        BaseAccordion,
        BaseAccordionItem,
        FormSubscribe,
    },

    computed: {
        showFormSubscribe() {
            const isGuest = !this.$store.getters['user/isLoggedin'];
            const notRoutes = !['Subscribed'].includes(this.$route.name);
            const notCartRoutes = ![
                'Cart',
                'CartIndex',
                'CartCheckout',
                'CartThankYou',
            ].includes(this.$route.name);
            const isUserSubscribed = !isGuest && this.$store.state.user.newsletter;
            let isVisible = false;

            if ((isGuest && notRoutes && notCartRoutes) || (!isUserSubscribed && notCartRoutes)) {
                isVisible = true;
            }

            return isVisible;
        },
        hasData() {
            return (this.footerState && this.$store.state.params.api && true) || false;
        },
        footerState() {
            return this.$store.state.footer;
        },
        quickLinks() {
            return this.$store.getters['footer/quickLinks'];
        },
        legalInfo() {
            return this.footerState.legalInfo[this.$store.state.params.country.id];
        },
        socialProfiles() {
            return this.footerState.socialProfilesLinks[this.$store.state.params.country.id];
        },
        formSubscribeNewsletter() {
            return this.$store.getters['shared/component']('formSubscribeNewsletter');
        },
        formSubscribeNewsletterHeader() {
            const newsletter = this.formSubscribeNewsletter;

            return {
                title: newsletter?.title || this.$t('layouts_subscription_box_title'),
                description: newsletter?.description || this.$t('layouts_subscription_subtitle'),
            };
        },
        isMobileApp() {
            return this.$store.state.params.source === SOURCE.APP;
        },
        isAccordionActive() {
            return this.$mediaBreakpointUp('md');
        },
    },

    created() {
        this.registerModule();
    },

    methods: {
        registerModule() {
            if (this.$store.hasModule(['footer'])) return;

            this.$store.registerModule('footer', footerModule, { preserveState: this.footerState });
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/the-footer";
</style>
