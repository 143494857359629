<template>
    <div class="accordion-item">
        <div class="accordion-header">
            <button
                class="accordion-btn"
                :class="{
                    'collapsed': !active
                }"
                :disabled="disabled"
                @click="toggle($event)"
            >
                {{ header }}
            </button>
        </div>
        <div
            class="accordion-body collapse"
            :class="{ 'show': active }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        header: {
            type: String,
            required: true,
        },
    },

    methods: {
        toggle(event) {
            const btn = event.target;
            const body = btn.parentElement.nextElementSibling;

            btn.classList.toggle('collapsed');
            body.classList.toggle('show');
        },
    },
};
</script>
